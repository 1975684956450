import { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [capVal, setCapVal] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wbw24pu",
        "template_0hgt3yv",
        e.target,
        "Kh5k0K1qs98wTpbZV"
      )
      .then(() => {
        alert("Message sent successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <>
      <div
        className="space-top"
        data-bg-src="images/bg_pattern_10.png"
        id="contact-sec"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pe-xxl-5">
              <div className="title-area mb-30">
                <span className="sub-title6">
                  CONTACT US
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">Have Some Questions?</h2>
              </div>
              <p className="mt-n2 mb-30">
                Thank you for your interest in our services. Please fill out the
                form below or email us, and we will get back to you promptly
                regarding your request.
              </p>
              <div className="btn-group style2">
                <a className="call-btn" href="tel:+18139941164">
                  <div className="play-btn">
                    <i className="fas fa-phone" />
                  </div>
                  +1 (813) 994 1164
                </a>
              </div>
            </div>
            <div className="col-xl-6 mt-40 mt-xl-0">
              <div className="contact-wrap3">
                <div className="contact-form-wrap">
                  <form
                    onSubmit={sendEmail}
                    className="contact-form ajax-contact"
                    method="post"
                  >
                    <input
                      type="hidden"
                      id="recaptchaResponse"
                      name="recaptchaResponse"
                    />
                    <h3 className="form-title">Request Scheduling</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="fullName"
                            placeholder="Full Name*"
                            type="text"
                            required="required"
                          />
                          <i className="fal fa-user" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="email"
                            placeholder="Email Address*"
                            type="email"
                            required="required"
                          />
                          <i className="fal fa-envelope" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="phone"
                            placeholder="Phone Number*"
                            type="tel"
                            required="required"
                          />
                          <i className="fal fa-phone" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            name="subject"
                            placeholder="Subject*"
                            type="text"
                            required="required"
                          />
                          <i className="fal fa-clipboard" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            cols={30}
                            name="message"
                            placeholder="Write Your Message*"
                            rows={3}
                            required="required"
                          />
                          <i className="fal fa-pen" />
                        </div>
                      </div>
                      <div className="form-btn col-12">
                        <ReCAPTCHA
                          sitekey="6LckIrcpAAAAAEKIsF1phIPq_I_s4xQDXwuK1qut"
                          onChange={(val) => setCapVal(val)}
                        />
                        <button
                          name="submit"
                          className="th-btn w-100"
                          type="submit"
                          disabled={!capVal}
                        >
                          Send Message
                          <i className="fas fa-long-arrow-right ms-2" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2031.5428438078698!2d-82.35857620895497!3d28.247789084469154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2b047b9195d6d%3A0x4e2b0e02a9c686fe!2s6225%20Old%20Pasco%20Rd%2C%20Wesley%20Chapel%2C%20FL%2033544!5e0!3m2!1sen!2sus!4v1701109311055!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
        />
      </div>
    </>
  );
};

export default Contact;
