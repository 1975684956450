import React from "react";

function About() {
  return (
    <div>
      <div
        className="th-container6 space-extra-top space-bottom"
        id="about-sec"
        style={{ paddingTop: 120 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="img-box11">
                <div className="img1">
                  <img
                    alt="About"
                    className="tilt-active"
                    src="images/large_about.jpg"
                  />
                </div>
                <div className="text-end">
                  <div className="img2">
                    <img
                      alt="About"
                      className="tilt-active"
                      src="images/small_about.jpg"
                    />
                  </div>
                </div>
                <div className="shape1">
                  <img alt="About" src="images/about_3_1.png" />
                </div>
                <div className="shape2">
                  <img alt="About" src="images/about_3_2.png" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 ps-xxl-5">
              <div className="title-area mb-30">
                <div className="shadow-title">ABOUT</div>
                <span className="sub-title6">
                  WHO WE ARE{" "}
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">IMT's Vision and Values</h2>
              </div>
              <p className="mt-n2 mb-4">
                Integrity Materials Testing (IMT) is a locally owned
                Construction Materials Testing company servicing the Tampa Bay
                area. When doing business with Integrity Materials Testing you
                will speak to a local employee who truly cares about our
                hometown. The goal of Integrity Materials Testing is to provide
                excellent customer service and on-time accurate results. IMT
                services public and private industries ranging from homeowners,
                building owners, contractors, developers and local
                municipalities. Our employees live and work in the Tampa Bay
                area and take pride in the work we do in our community. Our
                knowledge of local material and construction methods is our
                biggest asset and our employees have hands on in-house training
                as well as certifications from CTQP and ACI.
              </p>
              <div className="about-feature">
                <div className="btn-group style2">
                  <a className="th-btn" href="/services">
                    OUR SERVICES
                    <i className="fas fa-arrow-right ms-2" />
                  </a>{" "}
                  <a
                    className="ms-md-4 call-btn style2"
                    href="tel:+1-813-994-1164"
                  >
                    <div className="play-btn">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="btn-content">
                      <h6 className="btn-title">Have Some Questions?</h6>
                      <span className="btn-text">+1 (813) 994 1164</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
