import React from "react";
import ProjectCard from "../components/ProjectCard";

function Projects() {
  return (
    <section className="space" id="blog-sec">
      <div className="container">
        <div className="title-area text-center">
          <div className="shadow-title">Projects</div>
          <span className="sub-title6 justify-content-center">
            <span className="shape left">
              <span className="dots" />
            </span>{" "}
            IMT Projects{" "}
            <span className="shape right">
              <span className="dots" />
            </span>
          </span>
          <h2 className="sec-title">Latest Projects</h2>
        </div>
        <ProjectCard />
      </div>
    </section>
  );
}

export default Projects;
