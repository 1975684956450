import React from "react";

function Geophysical() {
  return (
    <section className="space-top space-extra2-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-lg-8">
            <div className="page-single">
              <div className="page-img mb-30">
                <img alt="Service Image" src="images/services/DSC_2306.JPG" />
              </div>
              <div className="page-content">
                <h2 className="h3 page-title">Geophysical Testing</h2>
                <p className="mb-30">
                  IMT’s team of experts is ready to help your projects
                  geophysical needs. Whether you are just wanting to know what
                  is below the soil or encountered a problem, we are here to
                  help. IMT is proud to offer CPT (Cone Penetration Test)
                  drilling with state-of-the-art equipment. Other geophysical
                  services are also utilized to solve your project’s unique
                  needs. Our timely results and recommendations will help you
                  make sound decisions for your project.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget_categories">
                <h3 className="widget_title">Categories</h3>
                <ul>
                  <li>
                    <a href="/soil">Soil Testing</a>
                  </li>
                  <li>
                    <a href="/geophysical">Geophysical Testing</a>
                  </li>
                  <li>
                    <a href="/concrete">Concrete Testing</a>
                  </li>
                  <li>
                    <a href="/asphalt">Asphalt Testing</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget widget_banner background-image"
                data-opacity={8}
                data-overlay="title"
                style={{ backgroundImage: 'url("images/DSC_0202.JPG")' }}
              >
                <div className="widget-banner">
                  <h4 className="title">Need Help? We Are Here To Help You</h4>
                  <a className="link" href="tel:+18139941164">
                    +1 (813) 994 1164
                  </a>
                  <a className="th-btn style3" href="/contact">
                    Contact Us Now <i className="far fa-arrow-right ms-1" />
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Geophysical;
