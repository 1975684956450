import React from "react";

function Soil() {
  return (
    <section className="space-top space-extra2-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-lg-8">
            <div className="page-single">
              <div className="page-img mb-30">
                <img alt="Service Image" src="images/services/DSC_1813.JPG" />
              </div>
              <div className="page-content">
                <h2 className="h3 page-title">Soil Testing</h2>
                <p className="mb-30">
                  IMT’s knowledge of local soil types and profiles is the
                  foundation of our company. Our team understands municipality
                  specifications and requirements to ensure the job is done
                  right. IMT performs a wide array of soil testing services with
                  state-of-the-art equipment. Our on-time accurate results will
                  keep your project moving along at a timely matter. No matter
                  what your soil testing needs, IMT will help get your project
                  out on the right step. Our services include and are not
                  limited to: Densities, Proctors, LBR/CBR, Soil Gradation and
                  -200 Washes, Atterberg Limits, Organic Content, and Moisture
                  Testing.
                </p>
                <div className="checklist style7">
                  <ul>
                    <li>Density Testing</li>
                    <li>Proctors</li>
                    <li>LBR</li>
                    <li>CBR</li>
                    <li>Gradation</li>
                    <li>Atterberg Limits</li>
                    <li>Organic Content</li>
                    <li>-200 Wash</li>
                    <li>Moisture Testing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget_categories">
                <h3 className="widget_title">Categories</h3>
                <ul>
                  <li>
                    <a href="/soil">Soil Testing</a>
                  </li>
                  <li>
                    <a href="/geophysical">Geophysical Testing</a>
                  </li>
                  <li>
                    <a href="/concrete">Concrete Testing</a>
                  </li>
                  <li>
                    <a href="/asphalt">Asphalt Testing</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget widget_banner background-image"
                data-opacity={8}
                data-overlay="title"
                style={{ backgroundImage: 'url("images/DSC_0202.JPG")' }}
              >
                <div className="widget-banner">
                  <h4 className="title">Need Help? We Are Here To Help You</h4>
                  <a className="link" href="tel:+18139941164">
                    +1 (813) 994 1164
                  </a>
                  <a className="th-btn style3" href="/contact">
                    Contact Us Now <i className="far fa-arrow-right ms-1" />
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Soil;
