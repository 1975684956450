import React from "react";

function Concrete() {
  return (
    <section className="space-top space-extra2-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-lg-8">
            <div className="page-single">
              <div className="page-img mb-30">
                <img alt="Service Image" src="images/services/DSC_1796.JPG" />
              </div>
              <div className="page-content">
                <h2 className="h3 page-title">Concrete Testing</h2>
                <p className="mb-30">
                  IMT offers a wide range of concrete testing services to ensure
                  that your construction project meets the highest standards of
                  quality and safety. Our team of experts is equipped with the
                  latest technology and equipment to provide accurate and
                  reliable results. Our services include Compressive Strength
                  Testing, Unit Weight, Grout Testing, Slump Testing, Air
                  Content, and Moisture Monitoring. We use ACI / ASTM standards
                  to perform our tests and provide detailed reports with the
                  results. Our test methods are designed to ensure that the
                  concrete used in your project is the right product to get the
                  job done right.
                </p>
                <div className="checklist style7">
                  <ul>
                    <li>Compressive Strength Testing</li>
                    <li>Unit Weight</li>
                    <li>Grout Testing</li>
                    <li>Slump Testing</li>
                    <li>Air Content</li>
                    <li>Moisture Monitoring</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget_categories">
                <h3 className="widget_title">Categories</h3>
                <ul>
                  <li>
                    <a href="/soil">Soil Testing</a>
                  </li>
                  <li>
                    <a href="/geophysical">Geophysical Testing</a>
                  </li>
                  <li>
                    <a href="/concrete">Concrete Testing</a>
                  </li>
                  <li>
                    <a href="/asphalt">Asphalt Testing</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget widget_banner background-image"
                data-opacity={8}
                data-overlay="title"
                style={{ backgroundImage: 'url("images/DSC_0202.JPG")' }}
              >
                <div className="widget-banner">
                  <h4 className="title">Need Help? We Are Here To Help You</h4>
                  <a className="link" href="tel:+18139941164">
                    +1 (813) 994 1164
                  </a>
                  <a className="th-btn style3" href="/contact">
                    Contact Us Now <i className="far fa-arrow-right ms-1" />
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Concrete;
