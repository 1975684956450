import React, { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Careers = () => {
  const [capVal, setCapVal] = useState(null);
  const [showDescription, setShowDescription] = useState({});

  const toggleDescription = (index) => {
    setShowDescription((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wbw24pu",
        "template_x2wjto7",
        e.target,
        "Kh5k0K1qs98wTpbZV"
      )
      .then(() => {
        alert("Message sent successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const jobPositions = [
    {
      title: "CONSTRUCTION MATERIALS FIELD TECHNICIAN",
      description: (
        <p>
          <h6>Job Responsibilities:</h6>
          <li>
            Travel to construction sites using a company-provided vehicle.
          </li>
          <li>
            Conduct sampling and testing of construction materials, including
            soil, concrete, and asphalt.
          </li>
          <li>Interpret construction plans and follow project directions.</li>
          <br />
          <h6>Working Conditions & Physical Requirements:</h6>
          <li>
            Work outdoors in varying weather conditions, including wet, humid,
            hot, and noisy environments.
          </li>
          <li>
            Frequently lift or move material samples or equipment weighing up to
            40 lbs.
          </li>
          <br />
          <h6>Minimum Qualifications:</h6>
          <li>High school diploma or equivalent.</li>
          <li>Must be at least 18 years old.</li>
          <li>Valid driver’s license with a clean driving record.</li>
          <li>
            Basic ability to read, write, and use technology such as smartphones
            and computers.
          </li>
          <br />
          <h6>Preferred Qualifications (Not Required):</h6>
          <li>
            Experience as a field or engineering technician, or in construction
            materials testing.
          </li>
          <li>Active ACI or CTQP certifications.</li>
          <br />
          <h6>Why Join Us?</h6>
          This position provides opportunities for career growth with paid
          certifications and training to advance your skills and expertise.
        </p>
      ),
    },
  ];

  return (
    <section className="space" id="blog-sec">
      <div className="container">
        <div className="title-area text-center">
          <div className="shadow-title">Careers</div>
          <span className="sub-title6 justify-content-center">
            <span className="shape left">
              <span className="dots" />
            </span>
            IMT Careers
            <span className="shape right">
              <span className="dots" />
            </span>
          </span>
          <h2 className="sec-title">Open Positions</h2>
        </div>

        {jobPositions.map((job, index) => (
          <section key={index} className="mt-5" id="open-positions">
            <div className="row">
              <div className="blog-content">
                <h6>
                  {/* While we don't have any open positions at the moment, we
                  encourage you to submit an application for future
                  opportunities. */}
                </h6>
                <h6 className="job-title">{job.title}</h6>
                <span>Full-time</span>
                <br />
                <br />
                <button
                  className="th-btn toggle-description"
                  onClick={() => toggleDescription(index)}
                >
                  {showDescription[index]
                    ? "Hide Description"
                    : "View Description"}
                </button>

                <div
                  className="job-description"
                  style={{ display: showDescription[index] ? "block" : "none" }}
                >
                  {job.description}
                </div>
              </div>
            </div>
          </section>
        ))}

        <section
          className="mt-5"
          id="application-form background-image"
          style={{ backgroundImage: "url(images/bg_pattern_10.png)" }}
        >
          <div className="contact-wrap3">
            <div className="contact-form-wrap">
              <h2 className="mb-4">Apply Now</h2>
              <form
                onSubmit={sendEmail}
                className="contact-form ajax-contact"
                method="post"
              >
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="firstName">
                      First Name*
                    </label>
                    <input
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      required=""
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="lastName">
                      Last Name*
                    </label>
                    <input
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="email">
                      Email Address*
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      required=""
                      type="email"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="phone">
                      Phone*
                    </label>
                    <input
                      className="form-control"
                      id="phone"
                      name="phone"
                      required=""
                      type="tel"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="location">
                      Location*
                    </label>
                    <input
                      className="form-control"
                      id="location"
                      name="location"
                      placeholder="e.g. 'Tampa, FL'"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="position">
                      Position*
                    </label>
                    <select
                      className="form-select"
                      id="position"
                      name="position"
                      required=""
                    >
                      <option disabled="" selected="" value="">
                        Select a Position
                      </option>
                      <option value="Open">Any</option>
                      <option value="Open">
                        Construction Materials Field Technician
                      </option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="required">Experience*</label>
                    <select
                      className="custom-select w-100"
                      name="experience"
                      required=""
                    >
                      <option selected="" />
                      <option value="0 Years">0 Years</option>
                      <option value="1-2 Years">0-2 Years</option>
                      <option value="3-5 Years">3-5 Years</option>
                      <option value="6-9 Years">6-9 Years</option>
                      <option value="10+ Years">10+ Years</option>
                    </select>
                  </div>
                  <div className="col-12 col-lg-4">
                    <label>How did you hear about us?</label>
                    <select className="custom-select w-100" name="hearAbout">
                      <option selected="" />
                      <option value="Ads">Ads</option>
                      <option value="Employee">Employee</option>
                      <option value="Friend">Friend</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="additionalInfo">
                    Additional Information (optional)
                  </label>
                  <textarea
                    className="form-control"
                    id="additionalInfo"
                    name="additionalInfo"
                    aria-required="true"
                    rows={3}
                    defaultValue={""}
                  />
                </div>
                <ReCAPTCHA
                  sitekey="6LckIrcpAAAAAEKIsF1phIPq_I_s4xQDXwuK1qut"
                  onChange={(val) => setCapVal(val)}
                />
                <button
                  className="th-btn"
                  name="submit"
                  type="submit"
                  disabled={!capVal}
                >
                  Submit Application
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Careers;
