import React, { useState } from "react";

function Nav() {
  // State to manage the visibility of the menu
  const [menuVisible, setMenuVisible] = useState(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <div
        className={`th-menu-wrapper ${menuVisible ? "th-body-visible" : ""}`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMenu}>
            <i className={`fal ${menuVisible ? "fa-times" : "fa-bars"}`} />
          </button>
          <div className="mobile-logo">
            <a href="/">
              <img alt="Konta" src="images/imt-small.png" />
            </a>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Services</a>
                <ul className="sub-menu">
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/soil">Soil Testing</a>
                  </li>
                  <li>
                    <a href="/geophysical">Geophysical Testing</a>
                  </li>
                  <li>
                    <a href="/concrete">Concrete Testing</a>
                  </li>
                  <li>
                    <a href="/asphalt">Asphalt Testing</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/projects">Projects</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout7">
        <div className="header-top">
          <div className="container">
            <div className="header-top-inner">
              <div className="row justify-content-center justify-content-md-between align-items-center gy-2">
                <div className="col-auto d-none d-md-block">
                  <div className="header-links d-none d-md-block">
                    <ul>
                      <li>
                        <i className="fal fa-envelope" />
                        <a href="mailto:info@integrity-mt.com">
                          info@integrity-mt.com
                        </a>
                      </li>
                      <li>
                        <i className="fal fa-phone" />
                        <a href="tel:+1-813-994-1164">1 (813) 994 1164</a>
                      </li>
                      <li>
                        <i className="fal fa-location-dot" />
                        <a
                          target="_blank"
                          href="https://maps.app.goo.gl/2tD3gXqawSEsdn2H7"
                        >
                          6225 Old Pasco Rd, Wesley Chapel, FL 33544
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="header-links">
                    <ul>
                      <li>
                        <div className="header-social">
                          <a
                            target="_blank"
                            href="https://www.facebook.com/Integritymaterialstesting"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/integrity-mt"
                          >
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="sticky-active">
            <div className="menu-area">
              <div className="container">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <div className="logo-style1">
                      <a href="/">
                        <img alt="Konta" src="images/imt-small.png" />
                      </a>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <nav className="main-menu d-none d-lg-inline-block">
                          <ul>
                            <li>
                              <a href="/">Home</a>
                            </li>
                            <li>
                              <a href="/about">About Us</a>
                            </li>
                            <li className="menu-item-has-children">
                              <a href="#">Services</a>
                              <ul className="sub-menu">
                                <li>
                                  <a href="/services">Services</a>
                                </li>
                                <li>
                                  <a href="/soil">Soil Testing</a>
                                </li>
                                <li>
                                  <a href="/geophysical">Geophysical Testing</a>
                                </li>
                                <li>
                                  <a href="/concrete">Concrete Testing</a>
                                </li>
                                <li>
                                  <a href="/asphalt">Asphalt Testing</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/projects">Projects</a>
                            </li>
                            <li>
                              <a href="/careers">Careers</a>
                            </li>
                            <li>
                              <a href="/contact">Contact</a>
                            </li>
                          </ul>
                        </nav>
                        <button
                          className="th-menu-toggle d-inline-block d-lg-none"
                          type="button"
                          onClick={toggleMenu}
                        >
                          <i
                            className={`fal ${
                              menuVisible ? "fa-times" : "fa-bars"
                            }`}
                          />
                        </button>
                      </div>
                      <div className="col-auto d-none d-xl-block">
                        <div className="header-button">
                          <a className="th-btn style3 ml-25" href="/contact">
                            Contact for scheduling
                            <i className="fas fa-arrow-right ms-2" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Nav;
