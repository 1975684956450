// Home.js
import React from "react";

const Home = () => {
  return (
    <>
      <div className="th-hero-wrapper hero-8" id="hero">
        <div
          className="hero-slider-8 th-carousel"
          data-adaptive-height="true"
          data-fade="true"
          data-md-slide-show={1}
          data-slide-show={1}
        >
          <div className="th-hero-slide">
            <div
              className="th-hero-bg"
              data-bg-src="images/IMG_2347.JPG"
              data-opacity={9}
              data-overlay="overlay2"
            />
            <div className="container">
              <div className="hero-style8">
                <span
                  className="hero-subtitle"
                  data-ani="slideinleft"
                  data-ani-delay="0.1s"
                >
                  Welcome To IMT
                </span>
                <h1
                  className="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.3s"
                >
                  Reliable Customer Service, Accurate Results
                </h1>
                <p
                  className="hero-text"
                  data-ani="slideinleft"
                  data-ani-delay="0.6s"
                >
                  Integrity Materials Testing (IMT) is a locally owned
                  Construction Materials Testing company servicing the Tampa Bay
                  area. When doing business with Integrity Materials Testing you
                  will speak to a local employee who truly cares about our
                  hometown.
                </p>
                <div
                  className="btn-group"
                  data-ani="slideinleft"
                  data-ani-delay="0.7s"
                >
                  <a className="th-btn" href="/services">
                    Our Services
                    <i className="fas fa-long-arrow-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="hero-img">
              <img alt="Hero Image" src="images/DSC_2306.JPG" />
              <button className="icon-btn" data-slick-next=".hero-slider-8">
                <i className="far fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="space" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="img-box9">
                <img alt="About" src="images/Imt-Home.png" />
              </div>
            </div>
            <div className="col-xl-6 ps-3 ps-xl-5">
              <div className="title-area mb-30">
                <span className="sub-title6">
                  ABOUT US
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">
                  Our Commitment to Quality and Expertise
                </h2>
              </div>
              <p className="mt-n2 mb-4">
                Integrity Materials Testing (IMT) is a locally-owned company in
                Tampa Bay that provides construction materials testing services
                to public and private industries, including homeowners, building
                owners, contractors, developers, and municipalities. Our goal is
                to offer excellent customer service and accurate results. Our
                employees, who live and work in the area, have in-house training
                and certifications from CTQP and ACI.
              </p>
              <div className="about-bg-shape">
                <div className="signature-box-wrap">
                  <a className="th-btn" href="/about">
                    Learn More
                    <i className="fas fa-arrow-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
