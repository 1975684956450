import React from "react";
import projects from "../projects.json";
import img from "../";

function ProjectCard() {
  return (
    <>
      {projects.projects.map((project) => (
        <div className="blog-card space-bottom">
          <div className="blog-content pt-0">
            <img src={project.image} alt="" />
            <div className="blog-meta style2">
              <a className="author" href="" tabIndex={-1}>
                <i className="author startdate fal fa-calendar-days" />
                {project.start_date}
              </a>
              <a href="" tabIndex={-1}>
                <i className="fal fa-calendar-days" />
                {project.end_date}
              </a>
            </div>
            <h3 className="blog-title">
              <a href="" tabIndex={-1}>
                {project.title}
              </a>
            </h3>
            <p className="blog-text">{project.description}</p>
          </div>
        </div>
      ))}
    </>
  );
}

export default ProjectCard;
