import React from "react";

function Services() {
  return (
    <section
      className="space bg-smoke background-image"
      id="service-sec"
      style={{ backgroundImage: "url(images/bg_pattern_10.png)" }}
    >
      <div className="container">
        <div className="title-area text-center">
          <span className="sub-title3">Our Services</span>
          <h2 className="sec-title">
            Our Services Will Keep Your Project Running Smoothly
          </h2>
        </div>
        <div className="row gy-4 justify-content-center">
          <div className="col-xl-6">
            <div className="service-card style3">
              <div className="service-card-thumb">
                <img alt="Icon" src="images/services/soil.png" />
                <div className="service-card-icon">
                  <img
                    alt="Icon"
                    src="images/services/icons/clipboard.png"
                    style={{ height: 50 }}
                  />
                </div>
              </div>
              <div className="service-card-content">
                <h3
                  className="box-title"
                  data-bs-target="#serviceModal"
                  data-bs-toggle="modal"
                >
                  <a href="/soil"> Soil Testing</a>
                </h3>
                <p className="service-card-text">
                  IMT’s knowledge of local soil types and profiles is the
                  foundation of our company.
                </p>
                <a className="link-btn style2" href="/soil">
                  Read More <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="service-card style3">
              <div className="service-card-thumb">
                <img alt="Icon" src="images/services/geophysical.png" />
                <div className="service-card-icon">
                  <img
                    alt="Icon"
                    src="images/services/icons/clipboard.png"
                    style={{ height: 50 }}
                  />
                </div>
              </div>
              <div className="service-card-content">
                <h3
                  className="box-title"
                  data-bs-target="#serviceModal"
                  data-bs-toggle="modal"
                >
                  <a href="/geophysical"> Geophysical Testing</a>
                </h3>
                <p className="service-card-text">
                  IMT’s team of experts is ready to help your projects
                  geophysical needs. Whether you are just wanting to know what
                  is below to the soil or encountered a problem, we are here to
                  help.
                </p>
                <a className="link-btn style2" href="/geophysical">
                  Read More <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="service-card style3">
              <div className="service-card-thumb">
                <img alt="Icon" src="images/services/concrete.png" />
                <div className="service-card-icon">
                  <img
                    alt="Icon"
                    src="images/services/icons/clipboard.png"
                    style={{ height: 50 }}
                  />
                </div>
              </div>
              <div className="service-card-content">
                <h3
                  className="box-title"
                  data-bs-target="#serviceModal"
                  data-bs-toggle="modal"
                >
                  <a href="/concrete"> Concrete Testing</a>
                </h3>
                <p className="service-card-text">
                  IMT offers a wide range of concrete testing services to ensure
                  that your construction project meets the highest standards of
                  quality and safety.
                </p>
                <a className="link-btn style2" href="/concrete">
                  Read More <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="service-card style3">
              <div className="service-card-thumb">
                <img alt="Icon" src="images/services/asphalt.png" />
                <div className="service-card-icon">
                  <img
                    alt="Icon"
                    src="images/services/icons/clipboard.png"
                    style={{ height: 50 }}
                  />
                </div>
              </div>
              <div className="service-card-content">
                <h3
                  className="box-title"
                  data-bs-target="#serviceModal"
                  data-bs-toggle="modal"
                >
                  <a href="/asphalt"> Asphalt Testing</a>
                </h3>
                <p className="service-card-text">
                  With a focus on quality assurance and control (QA/QC), IMT's
                  team of experts offers a wide range of services to ensure that
                  your asphalt surfaces are of the highest quality.
                </p>
                <a className="link-btn style2" href="/asphalt">
                  Read More <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
