import React from "react";

function Asphalt() {
  return (
    <section className="space-top space-extra2-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-lg-8">
            <div className="page-single">
              <div className="page-img mb-30">
                <img alt="Service Image" src="images/services/DSC_1793.JPG" />
              </div>
              <div className="page-content">
                <h2 className="h3 page-title">Asphalt Testing</h2>
                <p className="mb-30">
                  With a focus on quality assurance and control (QA/QC), IMT's
                  team of experts offers a wide range of services to ensure that
                  your asphalt surfaces are of the highest quality. Whether you
                  need monitoring during construction, thickness (coring)
                  measurement, material extraction, gradation analysis, rolling
                  straight edge testing or density testing, IMT has the
                  equipment and expertise to deliver accurate and reliable
                  results. With state-of-the-art technology and a commitment to
                  customer service, IMT is dedicated to providing the best
                  possible experience for clients in the construction industry.
                  Trust IMT to ensure your project's asphalt surfaces are built
                  to last.
                </p>
                <div className="checklist style7">
                  <ul>
                    <li>QA / QC</li>
                    <li>Monitoring</li>
                    <li>Thickness (coring)</li>
                    <li>Extraction</li>
                    <li>Gradation</li>
                    <li>Rolling Straight Edge</li>
                    <li>Density Testing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-4">
            <aside className="sidebar-area">
              <div className="widget widget_categories">
                <h3 className="widget_title">Categories</h3>
                <ul>
                  <li>
                    <a href="/soil">Soil Testing</a>
                  </li>
                  <li>
                    <a href="/geophysical">Geophysical Testing</a>
                  </li>
                  <li>
                    <a href="/concrete">Concrete Testing</a>
                  </li>
                  <li>
                    <a href="/asphalt">Asphalt Testing</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget widget_banner background-image"
                data-opacity={8}
                data-overlay="title"
                style={{ backgroundImage: 'url("images/DSC_0202.JPG")' }}
              >
                <div className="widget-banner">
                  <h4 className="title">Need Help? We Are Here To Help You</h4>
                  <a className="link" href="tel:+18139941164">
                    +1 (813) 994 1164
                  </a>
                  <a className="th-btn style3" href="/contact">
                    Contact Us Now <i className="far fa-arrow-right ms-1" />
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Asphalt;
